#actions{
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  max-width: 500px;
}

#actions > div{
  display: flex;
  flex-wrap: wrap;
}

#actions > div > *{
  flex-grow: 1;
  flex-basis: 0;
  white-space:nowrap;
}
