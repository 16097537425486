#downloadDialog textarea{
  font-size: 20px;
  width: calc(100% - 40px);
  margin: 10px 20px;
  padding: 10px;
  font-family: var(--font-family);
  border: solid var(--app-color) 5px;
  border-radius: 5px;
  resize: none;
  background-color: transparent;
  color: var(--text-color);
  transition: 0.2s ease;
}

#downloadDialog textarea:focus{
  outline: none;
  border-color: var(--app-color-medium);
}

#downloadButtons{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
