#playTypeToggle{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  border: solid var(--app-color) 7px;
  border-radius: 100px;
  padding: 0px;
  width: 100%;
  max-width: 300px;
  margin: 30px auto;
  overflow: hidden;
  background-color: var(--app-color)
}

#playTypeToggle > div{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  color: var(--text-color);
  border-radius: 5px;
  background-color: var(--background-color);
  transition: 0.2s ease;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

#playTypeToggle > div.disabled{
  color: var(--app-error-color);
  pointer-events: none;
}

#playTypeToggle.audio > div:nth-child(2){
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

#playTypeToggle.audio > div:nth-child(3){
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

#playTypeToggle.blink > div:nth-child(1){
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

#playTypeToggle.blink > div:nth-child(2){
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

#playTypeToggle > div.active{
  background-color: var(--app-color);
  color: white;
}

#playTypeToggle > div > span{
  margin-top: 5px;
  color: inherit;
}

#playTypeToggle > div > svg{
  color: inherit;
}
