*{
  box-sizing: border-box;
}

html{
  width: 100%;
  height: 100%;
}

body{
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: var(--font-family);
  background-color: var(--background-color);
  /* --background-color: #263238;
  --text-color: #CFD8DC;
  --app-color: #2196F3;
  --app-color-dark: #0D47A1;
  --app-color-medium: #1565C0;
  --app-error-color: #F44336; */

  --font-family: Roboto, Arial;
  --background-color: #263238;
  --text-color: #CFD8DC;
  --app-color: #EF6C00; /*500*/
  --app-color-medium: #E65100; /*800*/
  --app-error-color: #616161;
}

#root{
  height: 100%;
}

#app{
  display: flex;
  flex-direction: column;
  height: 100%;
}

#content{
  width: 100%;
  height: 100%;
  flex-shrink: 1;
  overflow: auto;
}

.view{
  width: 90%;
  margin: auto;
}

h1,h2,h3,h4,h5,h6{
  color: var(--app-color);
  text-align: center;
}

h1{
  font-size: 5rem;
  margin: 20px 0px;
}

a{
  text-decoration: none;
  display: block;
  position: relative;
  font-weight: bold;
  color: var(--text-color);
}

span{
  color: var(--text-color);
}

.MuiSvgIcon-root{
  color: var(--app-color);
  cursor: pointer;
}

@media only screen and (max-width: 500px) {
  #app{
    flex-direction: column-reverse;
  }

  h1{
    font-size: 3rem;
  }

}
