.title{
  display: flex;
  flex-direction: column;
  align-items: center;
}

#startNow > .navButton{
  width: 100%;
  margin: 40px auto;
  max-width: 300px;
}
