#deviceSyncronizer .holder{
  margin: 20px auto;
  display: flex;
  justify-content: center;
}

#deviceSyncronizer .holder canvas, #deviceSyncronizer .holder svg{
  border: solid white 10px;
  box-shadow: 0 7px 28px rgba(0,0,0,0.25), 0 5px 10px rgba(0,0,0,0.22);
  border-radius: 5px;
}

#deviceSyncronizer textarea{
  font-size: 20px;
  width: 100%;
  margin: 10px 0px;
  padding: 10px;
  font-family: var(--font-family);
  border: solid var(--app-color) 5px;
  border-radius: 5px;
  resize: none;
  background-color: transparent;
  color: var(--text-color);
  transition: 0.2s ease;
}

#deviceSyncronizer textarea:focus{
  outline: none;
  border-color: var(--app-color-medium);
}

#syncButtons{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
