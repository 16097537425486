.playCard{
  border: solid var(--app-color) 7px;
  border-radius: 7px;
  padding: 5px 10px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  max-width: 300px;
}

.playCard > h3{
  text-align: left;
  margin: 10px;
  flex-shrink: 1;
  word-wrap: break-word;
  min-width: 0px;
  hyphens: auto;
  color: var(--text-color);
}

.playCard > div{
  text-align: right;
  flex-shrink: 0;
}

.playCard > div > *{
  margin: 0px 5px;
}
