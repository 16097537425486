.button{
  border: solid var(--app-color) 7px;
  padding: 5px 10px;
  background-color: transparent;
  color: white;
  font-size: 1.5rem;
  border-radius: 5px;
  margin: 10px;
  cursor: pointer;
  transition: 0.3s ease;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
   -khtml-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.button:focus{
  outline: none;
}

.button:hover{
  border-color: var(--app-color-medium)
}
