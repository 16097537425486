.projectsContainer{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  grid-gap: 20px;
  grid-auto-flow: row;
}

.projectsContainer > a{
  width: 100%;
}

@media only screen and (max-width: 500px) {
  .projectsContainer{
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }
}
