#header{
  z-index: 10;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  background-color: var(--background-color);
  flex-shrink: 0;
}

#header h2{
  margin: 0px 30px 0px 10px;
  color: var(--app-color);
}

#navigation{
  display: flex;
  height: 100%;
}

#navigation > a{
  padding: 20px;
  display: flex;
  align-items: center;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

#navigation > a:focus{
  outline: none;
}

#navigation > a.active:after{
  bottom: 0;
  content: "";
  display: block;
  height: 3px;
  left: 0;
  position: absolute;
  right: 0;
  background: var(--app-color);
}

#navigation > a:hover{
  background-color: rgba(32,33,36,.04);
}

#navigation svg{
  display: none;
}

@media only screen and (max-width: 500px) {
  #header h2{
    margin: 0px 10px 0px 5px;
  }

  #header{
    box-shadow: 0 -3px 6px rgba(0,0,0,0.16), 0 -3px 6px rgba(0,0,0,0.23);
  }

  #header h2{
    display: none;
  }

  #navigation{
    width: 100%;
    justify-content: space-around;
  }

  #navigation > a{
    padding: 10px;
    font-size: 0.7em;
    flex-direction: column;
  }

  #navigation > a.active{
    color: var(--app-color);
  }

  #navigation > a.active:after{
    content: none;
  }

  #navigation > a > span{
    color: inherit;
  }

  #navigation svg{
    display: block;
  }

}
