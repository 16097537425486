.input{
  display: flex;
  border: solid var(--app-color) 7px;
  border-radius: 5px;
  margin: 10px;
}

.input > input{
  background-color: transparent;
  color: var(--text-color);
  border: none;
  border-right: solid var(--app-color) 7px;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 5px 10px;
  font-size: 1.5rem;
  width: 100%;
  min-width: 0px;
}

.input > input:focus{
  outline: none;
}

.input > button{
  border: none;
  padding: 5px 10px;
  background-color: transparent;
  color: var(--text-color);
  font-size: 1.5rem;
  cursor: pointer;
  transition: 0.3s ease;
  flex-shrink: 0;
}

.input > button:focus{
  outline: none;
}

.input > button:hover{
  background-color: var(--app-color);
}
