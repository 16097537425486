.collectionState{
  border: solid var(--app-color) 7px;
  border-radius: 5px;
  margin: 10px;
  width: 100%;
  max-width: 400px;
}

.collectionState h2{
  text-align: left;
  padding: 10px 10px 7px 10px;
  margin: 0px;
  border-bottom: solid var(--app-color) 7px;
}

.collectionState h3{
  margin: 10px;
  text-align: left;
}

.collectionState > ul{
  margin: 10px;
}

.collectionState li{
  color: var(--text-color);
  word-wrap: break-word;
}
