.playCards{
  display: flex;
  flex-wrap: wrap;
}

.playCards{
  display: flex;
  max-width: 3000px;
  margin: auto;
}
