.dialog{
  background-color: rgba(0,0,0,0.7);
  position: fixed;
  top:0;left:0;right:0;bottom:0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  opacity: 0;
  z-index: 100;
  transition: 0.3s ease;
}

.dialog.open{
  opacity: 1;
  pointer-events: auto;
}

.dialog > div{
  background-color: var(--background-color);
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  border-radius: 5px;
  padding: 20px;
  transform: scale(0.5);
  transition: 0.4s ease;
}

.dialog.open > div{
  transform: none;
}

.dialogTitle{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.dialogTitle > h2{
  margin: 0px;
}
