.imgButton{
  display: flex;
  align-items: center;
  margin: 20px;
  padding: 0px;
  overflow: hidden;
  background-color: var(--app-color);
  border: solid var(--app-color) 7px;
  border-radius: 100px;
  color: white;
  cursor: pointer;
}

.imgButton.isChild{
  margin: 0px;
  width: 100%;
  height: 100%;
}

.imgButton:hover, .imgButton:focus{
  outline: none;
}

.imgButton > svg{
  margin: 0px 10px;
  color: white;
  flex-shrink: 0;
  z-index: 2;
}

.imgButton > span{
  padding: 10px 20px;
  font-size: 20px;
  font-weight: normal;
  position: relative;
  z-index: 1;
  flex-grow: 1;
  text-align: left;
  display: flex;
  align-items: center;
}

.imgButton .background{
  background-color: var(--background-color);
  position: absolute;
  top:0; left:0; right:0; bottom:0;
  z-index: -1;
}

.imgButton .background:after{
  transition: 0.3s ease;
  content: '';
  background-color: var(--app-color);
  position: absolute;
  top:0; left:0; right:0; bottom:0;
  transform: translateX(-100%);
}

.imgButton:hover .background:after, .imgButton.active .background:after{
  transform: translateX(0%);
}
